import React, { useState } from "react";
import "../../../assets/styles/Burger.css";

export function Burger({ handleClicked }: { handleClicked: () => void }) {
    const [isClosed, setIsClosed ] = useState(true);

    const onClick = () => {
        handleClicked();
        setIsClosed(!isClosed);
    }
    
    return (
        <button className={ isClosed ? "BurgerOpen" : "BurgerClose"} onClick={ onClick }>
            <div className="BurgerLine"></div>
            <div className="BurgerLine"></div>
            <div className="BurgerLine"></div>
        </button>
    );
}