import React, { useState } from "react";

import "../../assets/styles/Close.css";

interface CloseProperties {
    onClick: () => void;
}

export function Close(props: CloseProperties) {    
    return (
        <>
            <a className="Close" onClick={ props.onClick }>X</a>
        </>
    );
}