import React from "react";
import { Routes, Route } from "react-router-dom";

import { About } from "./pages/About";
import { Contact } from "./pages/Contact";
import { Projects } from  "./pages/Projects";
import { Sent } from "./pages/Sent";

import { Analytics } from '@vercel/analytics/react'

import "./assets/styles/Theme.css"
import "./assets/styles/Global.css"

export class App extends React.Component {
  render() {
    return (
      <>
        <div>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/sent" element={<Sent />} />
          </Routes>
        </div>
        <Analytics mode={'production'}/>
      </>
    );
  }
}