import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { HashRouter  } from 'react-router-dom';

const container = document.getElementById('app-root')!;
const root = createRoot(container);
root.render(
    <StrictMode>
        <HashRouter >
            <App />
        </HashRouter >
    </StrictMode>
); 