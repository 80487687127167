import React from "react";

import { Close } from "./ui/Close";
import { ProjectLinks, ProjectProperties } from "../pages/Projects";
import Slider, { Settings } from "react-slick";
import "../assets/styles/ProjectPreview.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

export interface ProjectPreviewProps extends ProjectProperties {
    closeCallBack: () => void;
    slides: string[];
}

export function ProjectPreview(props: ProjectPreviewProps) {

    const getButtons = (links: ProjectLinks[]) => {
        const buttons: JSX.Element[] = [];

        for (const link of links) {
            buttons.push(<li key={ link.name }><button><a href={ link.link } target="_blank">{ link.name }</a></button></li>);
        }

        return <div className="previewLinks">{buttons}</div>;
    }

    const getSlides = (slideImages: string[]) => {

        if (!slideImages) { return; }

        const carouselSettings: Settings = {
            dots: true,
            infinite: true,
            speed: 750,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: "progressive",
            autoplay: true,
            centerMode: false,
            autoplaySpeed: 5000,
            touchMove: true,
            arrows: false,
            draggable: true
        }

        const slides: JSX.Element[] = [];
        for (let i = 0; i < slideImages.length; i++) {
            slides.push(<div id={`${i}`} key={`${i}`} ><img src={ slideImages[i] } key={i} /></div>);
        }

        const carousel = <li key="carousel"><Slider { ...carouselSettings }>{ slides }</Slider> </li>;

        return carousel;
    }

    return (
        <>
            <Close onClick={ props.closeCallBack }/>
            <div onClick={ props.closeCallBack } id="ProjectPreviewBacker"></div>
            <div id="ProjectPreview">
                <ul>
                    { getSlides(props.slides) }
                    { getButtons(props.links) }
                </ul>
            </div>
        </>
    );
}

export default ProjectPreview;