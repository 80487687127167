import React from "react";

export function ContactForm() {
    return (
        <>
            <div className="contact-form-container">
                <h2>Send me a message</h2>
                <form className="contact-form" action="https://api.web3forms.com/submit" method="POST">
                    <input type="hidden" name="access_key" value="08b7930a-03ec-4190-afd9-725b42840fd2"/>
                    <div>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="Full Name" placeholder="Your name..." maxLength={30} required />
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input 
                            type="email" 
                            name="email" 
                            size={ 30 }
                            maxLength={ 30 }
                            id="email" 
                            placeholder="Your email..."
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" placeholder="Your message..." maxLength={500} required />
                    </div>
                    <input type="checkbox" name="botcheck" id="" style={{ display: "none" }}></input>
                    <input type="hidden" name="redirect" value="https://rozen.savilla.co.uk/#/sent"></input>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </>
    )
}

export default ContactForm;