import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../../assets/styles/NavBar.css";

export function NavBar(toggle: { isOn: boolean }) {
    return (
        <>
            <div className={ toggle.isOn ? "NavBarOpen" : "NavBarClosed"}>
                <ul className="NavBar">
                    <li><Link className="NavItem" to="/">About</Link></li>
                    <li><Link className="NavItem" to="/projects">Projects</Link></li>
                    <li><Link className="NavItem" to="/contact">Contact</Link></li>
                </ul>
            </div>
            
        </>
    );
}