import React, { createElement, useState } from "react";

import { Layout } from "../layouts/Layout";
import { ProjectContainer } from "../components/ProjectContainer";
import { ProjectPreview, ProjectPreviewProps } from "../components/ProjectPreview";

import "../assets/styles/Projects.css";

import broodOfGodsLogo from "../assets/images/games/brood-of-gods/bog-logo.webp"
import broodOfGodsBase from "../assets/images/games/brood-of-gods/bog-base.webp"
import broodOfGodsFreeSpins from "../assets/images/games/brood-of-gods/bog-fs.webp"
import broodOfGodsPicker from "../assets/images/games/brood-of-gods/bog-picker.webp"

import sevensOnFireLogo from "../assets/images/games/7s-on-fire-deluxe/7s-on-fire-deluxe.webp"
import sevensOnFireBase from "../assets/images/games/7s-on-fire-deluxe/7s-on-fire-deluxe-base.webp"

import cluedoMightyways from "../assets/images/games/cluedo-mightyways/cluedo-mightyways-logo.webp"
import cluedoMightywaysBase from "../assets/images/games/cluedo-mightyways/cluedo-mightyways-base.webp"

import rrpmLogo from "../assets/images/games/rrpm/rrpm-logo.webp"
import rrpmBase from "../assets/images/games/rrpm/rrpm-base.webp"
import rrpmPicker from "../assets/images/games/rrpm/rrpm-picker.webp"

import rrpgLogo from "../assets/images/games/rrpg/rrpg-logo.webp"
import rrpgLoad from "../assets/images/games/rrpg/rrpg-load.webp"
import rrpgBase from "../assets/images/games/rrpg/rrpg-base.webp"

import spartacusMegawaysLogo from "../assets/images/games/spartacus-megaways/spartacus-megaways-logo.webp"
import spartacusMegwaysBase from "../assets/images/games/spartacus-megaways/spartacus-megaways-base.webp"

import monopolyGrandHotelLogo from "../assets/images/games/monopoly-grand-hotel/monopoly-grand-hotel-logo.webp"
import monopolyGrandHotelLoad from "../assets/images/games/monopoly-grand-hotel/monopoly-grand-hotel-load.webp"
import monopolyGrandHotelBase from "../assets/images/games/monopoly-grand-hotel/monopoly-grand-hotel-logo.webp"

import prismBlastLogo from "../assets/images/games/prism-blast/prism-blast-logo.webp"
import prismBlastBase from "../assets/images/games/prism-blast/prism-blast-base.webp"

import kronosUnleashedLogo from "../assets/images/games/kronos-unleashed/kronos-unleashed-logo.jpg"
import kronosUnleashedBase from "../assets/images/games/kronos-unleashed/kronos-unleashed-base.webp"

import dragonSpinPicknMixLogo from "../assets/images/games/dragon-spin-pick-n-mix/dragon-spin-pick-n-mix-logo.webp"
import dragonSpinPicknMixBase from "../assets/images/games/dragon-spin-pick-n-mix/dragon-spin-pick-n-mix-base.webp"
import dragonSpinPicknMixPick from "../assets/images/games/dragon-spin-pick-n-mix/dragon-spin-pick-n-mix-pick.webp"

import monopolyMillionaireLogo from "../assets/images/games/monopoly-millionaire/monopoly-millionaire-logo.webp"
import monopolyMillionaireBase from "../assets/images/games/monopoly-millionaire/monopoly-millionaire-base.webp"

import wildStreetsLogo from "../assets/images/games/wild-streets/wild-streets-logo.webp"
import wildStreetsBase from "../assets/images/games/wild-streets/wild-streets-base.webp"

import blazingXLogo from "../assets/images/games/blazing-x/blazing-x-logo.png"
import blazingXBase from "../assets/images/games/blazing-x/blazing-x-base.webp"

import dancingDrumsExplosionLogo from "../assets/images/games/dancing-drums-explosion/dancing-drums-explosion-logo.webp"
import dancingDrumsExplosionBase from "../assets/images/games/dancing-drums-explosion/dancing-drums-explosion-base.webp"

import nessiesTreasureMegaDropQuestLogo from "../assets/images/games/nessie's-treasure-mega-drop-quest/nessie's-treasure-mega-drop-quest-logo.webp"

import monopolyTravelWorldTourLogo from "../assets/images/games/monopoly-travel-world-tour/logo.png";
import monopolyTravelWorldTourBase from "../assets/images/games/monopoly-travel-world-tour/base.png";

export type ProjectLinks = { name: string, link: string };

export interface ProjectProperties {
  id: string;
  logoImage:string;
  slides: string[];
  links: ProjectLinks[];
}

type Project = { 
  [ id: string ]: ProjectProperties 
};

const projectsMap: Project = {
  "monopoly-travel-world-tour": {
    id: "monopoly-travel-world-tour",
    logoImage: monopolyTravelWorldTourLogo,
    slides: [ monopolyTravelWorldTourBase ],
    links: [
      { name: "Demo", link: "https://igaming-demo.lnw.com/games/all?query=monopoly#game70822" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Monopoly-Travel-World-Tour" }
    ]
  },
  "broodOfGods": {
    id: "broodOfGods",
    logoImage: broodOfGodsLogo,
    slides: [ broodOfGodsBase, broodOfGodsPicker, broodOfGodsFreeSpins ],
    links: [
      { name: "Demo", link: "https://igaming-demo.lnw.com/games/all?query=brood#game70765-desktop" },
      { name: "Info", link: "https://igaming.lnw.com/games/brood-of-gods/" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Brood-of-Gods" },
    ]
  },
  "7sOnFire": {
    id: "7sOnFire",
    logoImage: sevensOnFireLogo,
    slides: [ sevensOnFireBase ],
    links: [
      { name: "Demo", link: "https://igaming-demo.lnw.com/games/all?query=fire%20deluxe#game70783-desktop" },
      { name: "Info", link: "https://igaming.lnw.com/games/7s-on-fire-deluxe/" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/7-s-on-Fire-Deluxe" },
    ]
  },
  "rrpm": {
    id: "rrpm",
    logoImage: rrpmLogo,
    slides: [ rrpmBase, rrpmPicker ],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=power+mix" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Rainbow-Riches-Power-Mix" },
      { name: "YouTube", link: "https://www.youtube.com/watch?v=gWben_YnbQY" }
    ]
  },
  "cluedoMightyways": {
    id: "cluedoMightyways",
    logoImage: cluedoMightyways,
    slides: [ cluedoMightywaysBase ],
    links: [
      { name: "Demo", link: "https://igaming-demo.lnw.com/games/all?query=Cluedo#game70705-desktop" },
      { name: "Info", link: "https://igaming.lnw.com/games/cluedo-mightways-2/" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Cluedo-Mighty-Ways" },
    ]
  },
  "monopoly-grand-hotel": {
    id: "monopoly-grand-hotel",
    logoImage: monopolyGrandHotelLogo,
    slides: [ monopolyGrandHotelLoad, monopolyGrandHotelBase ],
    links: [
      { name: "Demo", link: "" },
      { name: "SlotsCatalog", link: "" },
    ]
  },
  "spartacus-megaways": {
    id: "spartacus-megaways",
    logoImage: spartacusMegawaysLogo,
    slides: [ spartacusMegwaysBase ],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=spartacus+megaways" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Spartacus-Megaways" },
    ]
  },
  "dancing-drums-explosion": {
    id: "dancing-drums-explosion",
    logoImage: dancingDrumsExplosionLogo,
    slides: [ dancingDrumsExplosionBase ],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=dancing+drums" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Dancing-Drums-Explosion" },
    ]
  },
  "blazing-x": {
    id: "blazing-x",
    logoImage: blazingXLogo,
    slides: [ blazingXBase],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=blazing+x" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Blazing-X" }
    ]
  },
  "rrpg": {
    id: "rrpg",
    logoImage: rrpgLogo,
    slides: [ rrpgLoad, rrpgBase ],
    links: [
      { name: "Demo", link: "" },
      { name: "SlotsCatalog", link: "" },
      { name: "YouTube", link: "https://www.youtube.com/watch?v=cKkEg48DNoM" }
    ]
  },
  "dragon-spin-pick-n-mix": {
    id: "dragon-spin-pick-n-mix",
    logoImage: dragonSpinPicknMixLogo,
    slides: [ dragonSpinPicknMixBase, dragonSpinPicknMixPick ],
    links: [
      { name: "Demo", link: "" },
      { name: "SlotsCatalog", link: "" }
    ]
  },
  "prism-blast": {
    id: "prism-blast",
    logoImage: prismBlastLogo,
    slides: [ prismBlastBase, monopolyGrandHotelBase ],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=prism+blast" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Prism-Blast" },
    ]
  },
  "nessie's-treasure-mega-drop-quest": {
    id: "nessie's-treasure-mega-drop-quest",
    logoImage: nessiesTreasureMegaDropQuestLogo,
    slides: [ nessiesTreasureMegaDropQuestLogo ],
    links: [
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Nessies-Treasure-Mega-Drop-Quest" }
    ]
  },
  "monopoly-millionaire": {
    id: "monopoly-millionaire",
    logoImage: monopolyMillionaireLogo,
    slides: [ monopolyMillionaireBase ],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=monopoly+millionaire"},
      { name: "Info", link: "https://igaming.lnw.com/games/monopoly-millionaire-us/" },
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Monopoly-Millionaire" }
    ]
  },
  "wild-streets": {
    id: "wild-streets",
    logoImage: wildStreetsLogo,
    slides: [ wildStreetsBase ],
    links: [
      { name: "Demo", link: "https://igaming.lnw.com/?s=wild+streets"},
      { name: "SlotsCatalog", link: "https://slotcatalog.com/en/slots/Wild-Streets" }
    ]
  },
  "kronos-unleashed": {
    id: "kronos-unleashed",
    logoImage: kronosUnleashedLogo,
    slides: [ kronosUnleashedBase ],
    links: [
      { name: "Demo", link: "" },
      { name: "SlotsCatalog", link: "" }
    ]
  }
}

export function Projects() {
  const [ preview, setPreview ] = useState<JSX.Element | null>(null);
  
  const closePreview = () => {
    setPreview(null);
    return;
  }

  const handleProjectSelected = (id: string) => {

    const previewElement = createElement(ProjectPreview, 
    { 
      ...projectsMap[id],
      closeCallBack: closePreview,
      slides: [
        ...projectsMap[id].slides
      ]
    });

    setPreview(previewElement);
  }

  const getProjects = (projectsMap: Project) => {
    const projectElements: JSX.Element[] = [];

    for (const [key, value] of Object.entries(projectsMap)) {
      projectElements.push(
        <li key={ value.id }>
          <ProjectContainer 
            id={ value.id } 
            logoImage={ value.logoImage }
            onSelected={ handleProjectSelected }
            slides= { value.slides }
            links={ value.links }
          />
        </li>
      ); 
    }

    return projectElements;
  }
  // TODO Hides projects
  return ( <> </>)

  return (
    <>
      { preview }
      <Layout>
        
        <div className="Projects">
            <section>
              <h2>iGaming Projects for Light & Wonder/ SG Digital / Red7</h2>
              <ul>
                { getProjects(projectsMap) }
              </ul>
            </section>
        </div>
      </Layout>
    </>
  );
}
