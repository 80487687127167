import React from "react";

import { Layout } from "../layouts/Layout";
import profilePicture from "../assets/images/profile-picture.webp";

import typescriptLogo from "../assets/images/logos/ts-logo-512.png";
import htmlLogo from "../assets/images/logos/html5.webp";
import cssLogo from "../assets/images/logos/css3.webp";
import javascriptLogo from "../assets/images/logos/javascript.webp";
import nodejsLogo from "../assets/images/logos/nodejs-light.webp";
import pixijsLogo from "../assets/images/logos/pixijs.webp";

import "../assets/styles/About.css";

export function About() {
  return (
    <>
      <Layout>
        <section className="intro">
          <ul className="intro-list">
            <li><img id="profilePicture" src={ profilePicture } alt="profilePicture" /></li>
            <ul className="intro-message-list">
              <li><p>Hi, I'm</p></li>
              <li><p>Rozen Savilla</p></li>
              <li><p>Front-End Game Developer</p></li>
            </ul>
          </ul>
        </section>

        <section className="tech">
          <ul className="tech-list">
            <li><img className="tech-logo" src={ typescriptLogo } alt="typescript-logo"></img></li>
            <li><img className="tech-logo" src={ htmlLogo } alt="html5-logo"></img></li>
            <li><img className="tech-logo" src={ cssLogo } alt="css3-logo"></img></li>
            <li><img className="tech-logo" src={ javascriptLogo } alt="javascript-logo"></img></li>
            <li><img className="tech-logo" src={ nodejsLogo } alt="nodejs-logo"></img></li>
            <li><img className="tech-logo" src={ pixijsLogo } alt="-pixijslogo"></img></li>
          </ul>
        </section>
      </Layout>
    </>
  );
}