import React, { useState } from "react";
import { Burger } from "../components/ui/burger/Burger";
import { NavBar } from "../components/ui/navBar/NavBar";

export function Layout({children}: {children: React.ReactNode}) {
    const [isNavOpen, setIsNavOpen ] = useState(false);

    return (
        <>
        <div>
            <Burger handleClicked={ () => setIsNavOpen(!isNavOpen) } />
            <NavBar isOn={ isNavOpen }/>
        </div>
        <main>{children}</main>
        </>
    );
}