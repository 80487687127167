import React from "react";

import "../assets/styles/ProjectContainer.css";
import { ProjectProperties } from "../pages/Projects";

export interface ProjectContainerProps extends ProjectProperties {
    onSelected: (id: string) => void;
}

export function ProjectContainer(props: ProjectContainerProps) {
    return (
        <button onClick={ () => { props.onSelected(props.id) } }>
            <div className="projectContainer">
                <img className="project-image" src={ props.logoImage } alt={props.id} />
            </div>
        </button>
    );
}

export default ProjectContainer;