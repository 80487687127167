import React from "react";

import { Layout } from "../layouts/Layout";

import liLogo from "../assets/images/logos/LI-Logo.png";

import "../assets/styles/Contact.css";

export function Sent() {
  return (
    <>
      <Layout>
        <section className="contact_socials">
        <a href="https://www.linkedin.com/in/rozensavilla/" target="_blank" rel="noreferrer">
          <button>
            <img src={liLogo} alt="LinkedIn Logo" />
          </button>
        </a>
        </section>
        <section className="contact">
            <h1>Message Sent</h1>
        </section>
      </Layout>
    </>
  )
}